<template>
  <ui-component-modal
    :modalTitle="$t('Components.Events.EventOrganizer.Delete.Main_Title')"
    :buttonLabelConfirm="
      $t('Components.Events.EventOrganizer.Delete.Button_Delete')
    "
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="saveEvent"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div>
          {{
            $t(
              'Components.Events.EventOrganizer.Delete.Message_AreYouSureDelete'
            )
          }}
        </div>
        <div>
          <label class="checkbox">
            <input
              type="checkbox"
              @click.ctrl.left.exact="deleteLink"
              v-model="isDeleteConfirmed"
            />
            {{
              $t('Components.Events.EventOrganizer.Delete.Message_YesImSure')
            }}
          </label>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import eventProvider from '@/providers/event'

export default {
  components: {},

  props: {
    organizer: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {
    ...mapState('eventStore', ['eventData']),
  },

  created() {
    this.mEvent = JSON.parse(JSON.stringify(this.eventData))
  },

  methods: {
    ...mapMutations('eventStore', ['setEventData']),

    saveEvent() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        let organizerIndex = self.mEvent.Organizers.findIndex(
          (o) => o.Id === self.organizer.Id
        )
        if (organizerIndex > -1) {
          self.mEvent.Organizers.splice(organizerIndex, 1)
        }

        eventProvider.methods
          .updateEvent(self.mEvent)
          .then((response) => {
            if (response.status === 200) {
              self.isDeletingSuccess = true
              self.setEventData(response.data)

              let t = setTimeout(() => {
                self.onClickCancel()
                clearTimeout(t)
              }, 1500)
            }
          })
          .catch((e) => {
            ////console.log(error.response)
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  z-index: 102;
}
</style>
